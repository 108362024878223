.plasticprocess-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('../photo/plasticProcess/plasticprocess_background.png');
  background-size: cover;
  background-position: center;
  z-index: -1;
}

 .plasticProcess-title {
  text-align: center;
  margin-bottom: 3%;
} 

.plasticProcess-title1 {
  text-align: center;
  font-size: 1.6em;
}
.plasticProcess-title2 {
  text-align: center;
  font-size: 2.2em;
  font-weight: 400;
}

.plasticProcess-box-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 4%;
}

.plasticProcess-box {
  margin: 5px;
  text-align: center;
  width: 15%;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box; 
}

.plasticProcess-box img {
  width: 100%;
  height: auto;
  padding:3%;
}

.description {
  margin-top: 2%;
  font-size: 1.2em;
  text-align: center;
  font-weight: bolder;
}
@media (min-width: 1920px) {
  .content {
    padding: 50px;
  }
}
/* 반응형 디자인을 위한 미디어 쿼리
@media (max-width: 1200px) {
  .plasticProcess-box {
    width: 180px;
    padding: 15px;
  }

  .description {
    font-size: 14px;
  }
}

@media (max-width: 992px) {
  .plasticProcess-box {
    width: 150px;
    padding: 10px;
  }

  .description {
    font-size: 12px;
  }
}

@media (max-width: 768px) {
  .plasticProcess-title {
    padding-top: 15%; /* 타이틀과 네비게이션 바 사이의 간격을 더 늘림 
    margin-bottom: 10px;
  }

  .five-box-container {
    margin-top: 10%;
    flex-direction: column;
    align-items: center;
  }

  .five-box {
    width: 80%;
    margin: 10px 0;
  }
}

@media (max-width: 576px) {
  .five-title {
    padding-top: 20%;
    margin-bottom: 10px;
  }

  .five-box {
    width: 90%;
    margin: 10px 0;
  }
} */
