.plasticinfo-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('../photo/plasticInfo/plasticinfo_background.png');
  background-size: cover;
  z-index: -1;
}

.plasticInfo-box-container {
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  padding: 8%;
}

.plasticInfo-box {
  background-color: white;
  color: black;
  padding: 1.5%;
  border: 1px solid black;
  text-align: left;
  font-size: 1.2em;
  margin: 7px;
  font-weight: 800;
}

.smalltitle {
  font-size: 1.2em;
  font-weight: 900;
}

.large-box {
  margin-top: 8%;
  width: 23%;
  font-size: 1.6em;
  padding-left: 3%;
  line-height: 1.6;
}

.largeinfo {
  font-size: 0.8em;
}

.small-box {
  margin-top: 8%;
  display: flex;
  flex-direction: column; 
  width: 20%;
  height: auto;
}

.title-img-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 5%;
}

.small-box img {
  width: 50%;
  height: auto;
  margin-left: 7%;
}

.smallinfo {
  margin-left: 40%;
  font-size: 1em;
}

.plasticInfo-highlight {
  color: red;
  font-weight: bold;
  font-size: 0.8em;
}

.plasticInfo-highlight2 {
  color: red;
  font-weight: bolder;
}

/* 미디어 쿼리 추가 - 모바일에서 세로 정렬 및 크기 조정 */
@media (max-width: 945px) {
  .plasticInfo-box-container {
    flex-direction: column;
    align-items: center;
  }

  .large-box, .small-box {
    width: 60%; /* 모든 박스 크기를 동일하게 조정 */
    margin: 20px 0;
  }

  .plasticInfo-box {
    font-size: 0.9em; /* 글자 크기 조금 줄임 */
    padding: 2%; /* 패딩도 조정 */
  }

  .smallinfo {
    margin-left: 0; /* 텍스트 중앙 정렬 */
    text-align: center;
  }

  .small-box img {
    width: 30%; /* 이미지 크기도 모바일에서 확대 */
    margin-left: 10%; /* 이미지를 중앙에 위치 */
  }
}
