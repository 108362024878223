.home {
  position: relative;
  height: 100vh;
  overflow: auto;
}

.header {
  position: relative;
  min-height: 100vh; 
  overflow: hidden;
}
.pmintro-header{
  position: relative;
  height: 100vh; 
  overflow: hidden; 
}

.pmintro-background-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('../photo/pmintro/pmintro_background.png');
  background-size: cover;
  background-position: center;
  z-index: -1; 
}

.content {
  position: relative;
  color: black;
  z-index: 1;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%; 
  min-height: 100vh; /*전체 화면 때문에 따로 추가*/
}

.ppmlogo {
  position: relative;
  bottom: 13%;
  width: 13%; 
  height: auto; 
  z-index: 2; 
}

.socialg {
  position: relative;
  bottom: 11%;
  width: 13%; 
  height: auto;
  z-index: 1;
}

.ppm {
  position: relative; 
  top: 23%;
  width: 34%; 
  height: auto;
  z-index: 3;
}
.yellow{
  position: relative; 
  top: 21%;
  left: 4%;
  width: 8.5%; 
  height: auto;
  z-index: 5;
}

.home-nav {
  position: fixed;
  top: 20px;
  right: 50px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  z-index: 10;
}

.circle-button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 85px;
  height: 40px;
  margin: 0 5px;
  text-decoration: none;
  background-color: white;
  color: black;
  font-weight: bold;
  font-size: 1.2rem;
  border: 2.5px solid black;
  border-radius: 50%;
  text-align: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.circle-button:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.615);
  transform: translateY(-2px);
}

.hamburger {
  display: none;
  font-size: 3rem;
  cursor: pointer;
}

.menu {
  display: flex;
  flex-direction: row;
}

@keyframes slideDown {
  from {
    transform: translateY(-100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes slideUp {
  from {
    transform: translateY(0);
    opacity: 1;
  }
  to {
    transform: translateY(-100%);
    opacity: 0;
  }
}
@media (min-width: 1920px) {
  .content {
    padding: 50px;
  }
}

@media (max-width: 1024px) {
  .home-nav {
    flex-direction: column;
    align-items: flex-end;
    top: 20px;
    right: 20px;
  }

  .menu {
    flex-direction: column;
  }

  .circle-button {
    width: 100px;
    margin: 5px 0;
  }
}

@media (max-width: 950px) {
  .home-nav {
    align-self: flex-end;
  }

  .hamburger {
    display: block;
  }

  .menu {
    display: none;
    flex-direction: column;
    width: 200px;
    background-color: white;
    border: 1px solid black;
    border-radius: 8px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    position: absolute;
    top: 40px;
    right: 20px;
  }

  .menu.open {
    display: flex;
    animation: slideDown 0.3s forwards;
  }

  .circle-button {
    width: 100%;
    height: 50px;
    border: none;
    border-bottom: 1px solid black;
    border-radius: 0;
  }

  .circle-button:last-child {
    border-bottom: none;
  }
  .ppmlogo, .socialg {
    width: 30%; /* 모바일 화면에서 이미지가 더 커지도록 조정 */
    margin-bottom: 20px;
  }
  .ppm{
    width: 40%;
  }
  
}
@media (max-width: 600px) {
  .ppmlogo, .socialg {
    width: 30%; /* 더 작은 화면에서 이미지 크기 추가 조정 */
  }
  .ppm{
    width: 70%;
  }
  .yellow{
    width: 20%;
  }
}

