.manito-header {
  position: relative;
  min-height: 100vh; 
}
.manito-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('../photo/manito/manito_background.png');
  background-size: cover;
  background-position: center;
  z-index: -1;
}

.main-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh; 
}

.content-body {
  display: flex;
  flex-direction: row; 
  width: 100%;
}

.selection-steps {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 20%;
  padding-left: 10%;
}

.step {
  display: flex;
  align-items: center;  
}

.step-title {
  position: relative;
  align-items: center;
  margin-top:2%;
  background-color: #ffeb3b; 
  width: 130px;  
  height: 100px; 
  margin-right: 20px;
  border: 2px solid black;
  border-radius: 20%;

}

.step-circle {
  width: 125px;
  height: 60px;
  background-color: red; 
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 40%;
  font-size: 1.3em;
  color: white;
  font-weight: bolder;
  position: absolute;
  top: -26px;
  border: 2px solid black; 
}

.step-text {
  font-size: 1.3em;
  font-weight: bold;
  margin-top: 50px; 
}

.step button:hover {
  background-color: #f4c988; 
}

.step button.selected {
  background-color: #ffff49; 
  transform: scale(1.1);
}

.options-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
}

.options {
  display: flex;
  flex-direction: row;
  margin-left: 30px;
}

.options button {
  width: 130px;
  padding-top: 100%;
  background-color: #FEFCD3;
  border: 2px solid black; 
  border-radius: 50%; 
  margin: 10px;
  padding: 10px;
  cursor: pointer;
  font-size: 0.9em;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative; 
  overflow: hidden; 
  transition: transform 0.3s ease, background-color 0.3s ease;
}

.option-text {
  background-color: white;
  font-size: 0.8em;
  border: 1px solid black; 
  font-weight: bold;
  padding: 3%;
}

.option-image {
  width: 115px;
  height: 90px; 
  object-fit: contain;
  position: relative; 
  
}

.arrow-image {
  width: 3%; 
  height: 7%;
  position: absolute;
  top: 50%; 
  left: 55%;
  transform: translate(-50%, -50%); 
  margin: 0; 
}


.result-display {
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid black; 
  background-color:#FFFABC;
  margin-top: 2%;
  margin-left: 28%;
  border-radius: 20%; 
  width: 30%;
  height: 550px;
  overflow: hidden;
  position: relative;
}

.result-box {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow-y: auto; 
}

.result-display h2 {
  margin-bottom: 20px;
}

.result-box img {
  height: auto;
  margin: 5px;
}

.selected-image {
  position: absolute;
}

.selected-pot {
  width: 40%;
  top: 53%;
  left: 49%;
  transform: translate(-50%, -50%);
  z-index: 2;
}

.selected-plant {
  width: 58%; 
  top: 33%;
  left: 51%;
  transform: translate(-50%, -50%);
}

.selected-manito {
  width: 25%; 
  top: 85%;
  left: 73%;
  transform: translate(-50%, -50%);
}

.selected-accessory {
  width: 36%; 
  top: 68%;
  left: 74%;
  transform: translate(-50%, -50%) rotate(90deg);
  z-index: 3;
}

.behind-pot {
  z-index: 1; 
  width: 60%;
  top: 21%; 
  left: 50%;
}

.behind-pot2 {
  z-index: 1; 
  width: 60%;
  top: 24%; 
  left: 50%;
}
.in-front-pot {
  z-index: 3;
}

@media (max-width: 1980px) {
  .content-body {
    transform: scale(1);
    width: 100%;
  }
  .behind-pot {
    z-index: 1; 
    width: 60%;
    top: 16%; 
    left: 50%;
  }
  
  .behind-pot2 {
    z-index: 1; 
    width: 60%;
    top: 20%; 
    left: 50%;
  }
  .in-front-pot {
    z-index: 3;
  }
}
@media (max-width: 1650px) {
  .content-body {
    transform: scale(1);
    width: 100%;
  }
  .behind-pot {
    z-index: 1; 
    width: 60%;
    top: 20%; 
    left: 50%;
  }
  
  .behind-pot2 {
    z-index: 1; 
    width: 60%;
    top: 23%; 
    left: 50%;
  }
  .in-front-pot {
    z-index: 3;
  }
}

@media (max-width: 1480px) {
  .content-body {
    transform: scale(1);
    width: 100%;
  }
  .behind-pot {
    z-index: 1; 
    width: 60%;
    top: 15%; 
    left: 50%;
  }
  
  .behind-pot2 {
    z-index: 1; 
    width: 60%;
    top: 19%; 
    left: 50%;
  }
  .in-front-pot {
    z-index: 3;
  }
}

@media (max-width: 1470px) {
  .content-body {
    transform: scale(0.9);
    width: 110%;

  }
  .behind-pot {
    z-index: 1; 
    width: 60%;
    top: 21%; 
    left: 50%;
  }
  
  .behind-pot2 {
    z-index: 1; 
    width: 60%;
    top: 25%; 
    left: 50%;
  }
  .in-front-pot {
    z-index: 3;
  }
  
}
@media (max-width: 1240px) {
  .content-body {
    transform: scale(0.9);
    width: 120%;

  }
  .behind-pot {
    z-index: 1; 
    width: 60%;
    top: 26%; 
    left: 50%;
  }
  
  .behind-pot2 {
    z-index: 1; 
    width: 60%;
    top: 29%; 
    left: 50%;
  }
  .in-front-pot {
    z-index: 3;
  }
  
}
@media (max-width: 1100px) {
  .content-body {
    transform: scale(0.8);
    width: 140%;

  }
  .behind-pot {
    z-index: 1; 
    width: 60%;
    top: 22%; 
    left: 50%;
  }
  
  .behind-pot2 {
    z-index: 1; 
    width: 60%;
    top: 25%; 
    left: 50%;
  }
  .in-front-pot {
    z-index: 3;
  }
  
}
@media (max-width: 1024px) {
  .content-body {
    flex-direction: column; /* 세로 정렬 */
    align-items: center;
    margin-right: 50%;
    width: 100%;
    transform: scale(0.6);

  }

  .result-display {
    margin-top: 10%; /* 위쪽 여백 추가 */
    margin-left: 50%; /* 왼쪽 여백 제거 */
    width: 70%; /* 크기 조정 */
  }
  .arrow-image {
    width: 3%; 
    height: 7%;
    position: absolute;
    top: 52%; 
    left: 80%;
    transform: translate(-50%, -50%) rotate(90deg); 
    margin: 0; 
  }
  .behind-pot {
    z-index: 1; 
    width: 60%;
    top: 22%; 
    left: 50%;
  }
  
  .behind-pot2 {
    z-index: 1; 
    width: 40%;
    top: 25%; 
    left: 50%;
  }
  .in-front-pot {
    z-index: 3;
  }

  .selected-plant {
    width: 40%; 
    top: 25%;
    left: 51%;
    transform: translate(-50%, -50%);
  }
  
  .selected-manito {
    width: 20%; 
    top: 85%;
    left: 73%;
    transform: translate(-50%, -50%);
  }
  
  .selected-accessory {
    width: 30%; 
    top: 60%;
    left: 70%;
    transform: translate(-50%, -50%) rotate(90deg);
    z-index: 3;
  }
  .selected-pot {
    width: 30%;
    top: 53%;
    left: 49%;
    transform: translate(-50%, -50%);
    z-index: 2;
  }
  
  
}

@media (max-width: 768px) {
  .content-body {
    transform: scale(0.6);
    width: 200%;
  }
}

@media (max-width: 600px) {
  .content-body {
    transform: scale(0.5);
    width: 200%;
  }
}

@media (max-width: 480px) {
  .content-body {
    transform: scale(0.4);
    width: 160%;

  }
}
