.header {
  position: relative;
  text-align: center;
  color: white;
}

.plasticintro-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('../photo/plasticIntro/plasticintro_background.png');
  background-size: cover;
  background-position: center;
  z-index: -1;
}

.content {
  position: relative;
  z-index: 1;
}

.plasticIntro-box-container {
  display: flex;
  justify-content: center;
  width: 70%;
}

.plasticIntro-box {
  background-color: white;
  color: black;
  padding: 2%;
  border: 1px solid black;
  width: 80%;
  text-align: left;
  font-size: 2em;
  font-weight: 800;
  line-height: 1.5;
  margin: 80px;
}

@media (min-width: 1920px) {
  .content {
    padding: 50px;
  }
}

/* 모바일 크기에서의 스타일 변경 */
@media (max-width: 945px) {
  .plasticIntro-box-container {
    flex-direction: column;
    align-items: center;
  }

  .plasticIntro-box {
    width: 90%; /* 네모 크기를 조금 작게 설정 */
    margin: 20px; /* 간격을 더 좁게 설정 */
    font-size: 1.5em; /* 글자 크기도 조금 줄임 */
  }
}
