.notices-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('../photo/notices_background.png');
  background-size: cover;
  background-position: center;
  z-index: -1;
}
