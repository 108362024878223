/* 전체 컨테이너 설정 */
.location-container {
  position: relative;
  min-height: 100vh;
}

/* 배경 이미지 설정 */
.location-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('../photo/location_background.png'); /* 배경 이미지 */
  background-size: cover;
  background-position: center;
  z-index: -1;
}

/* 내용 컨테이너 설정 */
.location-content {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

/* 정보 박스 설정 */
.location-box {
  background-color: white;
  padding: 20px;
  border-radius: 15px;
  text-align: center;
  width: 80%; /* 넓이 조절 */
  max-width: 800px; /* 최대 넓이 */
}

/* 지도 이미지 설정 */
.location-map {
  max-width: 60%;
  height: auto;
  margin-bottom: 5px;
}

/* 주소 텍스트 스타일 */
.location-address {
  font-weight: bold;
  font-size: 1.2em;
}

/* 주차 안내 텍스트 스타일 */
.location-parking {
  font-size: 1em;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: left; /* 텍스트를 왼쪽 정렬 */
  line-height: 1.6

}

/* 화살표 아이콘 스타일 */
.arrow-icon {
  width: 20px;
  height: 20px;
  margin-bottom: 45px;
}

/* 문의 텍스트 스타일 */
.location-contact {
  margin-top: 40px;
  font-size: 1em;
}

/* 문의 글자만 크게 */
.location-inquiry {
  font-size: 1.3em; /* '문의' 텍스트 크기만 크게 */
  margin-right: 10px;
}
